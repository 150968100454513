<template>
        <div class="main">
            <div class="container">
                <div class="section">
                	<h2 class="title text-center">Featured Study Sets</h2>
                	<h4 style="margin-bottom: 30px;" class="description text-center">Discover new study sets with flashcards</h4>
                    <div class="row">
                        <div v-for="(item, index) in items" class="col-md-4">
                        	<a :href="item.link">
                            <card type="blog" :href="item.link" plain>
                                <img slot="image" class="img rounded img-raised" :src="item.bg"/>
                                <div>
                                	<h6 class="category float-left" :class="item.addclass">{{ item.cat }}</h6>
                                	<h6 class="text-right category text-secondary">{{ item.nCards }} Cards</h6>
                                </div>
                                <h4 class="card-title">
                                    <a :href="item.link">{{ item.title }}</a>
                                </h4>
                                <p class="card-description">
                            		{{ item.desc }}    	
                                </p>
                                <div class="author">
                                    <img v-lazy="item.authorpic" alt="Author" class="avatar img-raised">
                                    <span>{{ item.author }}</span>
                                </div>
                            </card></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="subscribe-line subscribe-line-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <h4 class="title">Get Tips &amp; Tricks every Week!</h4>
                            <p class="description">
                                Join our newsletter and get news in your inbox! We hate spam too, so no
                                worries about this.
                            </p>
                        </div>
                        <div class="col-md-6">
                            <card plain class="card-form-horizontal">
                                <div class="row">
                                    <div class="col-sm-8">
                                        <fg-input addon-left-icon="now-ui-icons ui-1_email-85"
                                                  placeholder="Email Name...">
                                        </fg-input>
                                    </div>
                                    <div class="col-sm-4">
                                        <n-button type="primary" round block>Subscribe
                                        </n-button>
                                    </div>
                                </div>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import { Card, Button, FormGroupInput } from "@/components";

export default {
  name: "practice-tests",
  bodyClass: "blog-posts",
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      year: new Date().getFullYear(),
      items: [
        {
          title: "Deep Work by Cal Newport",
          cat: "Studying, Productivity",
          addclass: "text-warning",
          desc: "Rules for Focus in a Distracted World.",
          nCards: 33,
          bg: "/img/tests/prod/deep-work-bg6-sm.jpg",
          link: "/practicetests/deepwork-focus-in-distracted-world/",
          author: "Dr. Cal Newport",
          authorpic: "/img/michael.jpg"
        },
        {
          title: "Scaling Teams",
          cat: "Startups, Business",
          addclass: "text-info",
          desc:
            "Building Successful Teams and Orgs...",
          nCards: 32,
          bg: "/img/tests/001-scaling-teams-bg24-small.jpg",
          link:
            "/practicetests/scaling-teams-strategies-building-successful-teams/",
          author: "D. Loftesness, A. Grosse",
          authorpic: "/img/james.jpg"
        },
        {
          title: "Network Security",
          cat: "Networks",
          addclass: "text-info",
          desc: "Questions about Network Security",
          nCards: 51,
          bg: "/img/tests/pixb/net-security-hacker-small.jpg",
          link: "/practicetests/inf/network-security/",
          author: "Informatics",
          authorpic: "/img/user/informatics.jpg"
        },
        {
          title: "Data Link Layer (TCP/IP)",
          cat: "Networks",
          addclass: "text-info",
          desc: "A set of questions about the Data Link Layer.",
          nCards: 38,
          bg: "/img/bg19.jpg",
          link: "/practicetests/data-link-layer/",
          author: "Informatics",
          authorpic: "/img/user/informatics.jpg"
        },
        {
          title: "Network Layer (TCP/IP)",
          cat: "Networks",
          addclass: "text-info",
          desc: "A set of questions about the Data Link Layer.",
          nCards: 39,
          bg: "/img/tests/networks-tree-200795-small.jpg",
          link: "/practicetests/inf/network-layer-tcp-ip/",
          author: "Informatics",
          authorpic: "/img/user/informatics.jpg"
        },
        {
          title: "Transport Layer (TCP/IP)",
          cat: "Networks",
          addclass: "text-info",
          desc: "A set of questions about the Data Link Layer.",
          nCards: 37,
          bg: "/img/tests/transport-card-project6.jpg",
          link: "/practicetests/inf/transport-layer-tcp-ip/",
          author: "Informatics",
          authorpic: "/img/user/informatics.jpg"
        },
        {
          title: "Design Patterns",
          cat: "Software Design",
          addclass: "text-info",
          desc: "A set of questions about Design Patterns.",
          nCards: 1,
          bg: "/img/tests/inf/se/design-patterns-small.jpg",
          link: "/practicetests/software-engineering/design-patterns/",
          author: "Informatics",
          authorpic: "/img/user/informatics.jpg"
        }
        /*{title: "Boolean Algebra", cat: 'Informatics', addclass: 'text-info',
      			desc: "A practice test about 'Boolean Algebra'.",
      			nCards: 32, 
      			bg: '/img/bg24.jpg',
      			link: '/practicetests/boolean-algebra/',
      			author: 'D. Loftesness, A. Grosse', authorpic: '/img/james.jpg' },*/
        /*{title: 'The Art of War — Applied to Business and Marketing', cat: 'Startups, Business', addclass: 'text-secondary',
      			desc: 'Done', 
      			bg: '/img/bg4.jpg',
      			link: '/practicetests',
      			author: 'An Author', authorpic: '/img/ryan.jpg' },
      		{title: 'Scaling Teams by Alexander Grosse', cat: 'Startups, Business', addclass: 'text-success',
      			desc: 'Done', 
      			bg: '/img/bg7.jpg',
      			link: '/practicetests',
      			author: 'An Author', authorpic: '/img/usher.jpg' },
      		{title: 'Scaling Teams by Alexander Grosse', cat: 'Startups, Business', addclass: 'text-danger',
      			desc: 'Done', 
      			bg: '/img/bg8.jpg',
      			link: '/practicetests',
      			author: 'An Author', authorpic: '/img/usher.jpg' }*/
      ]
    };
  }
};
</script>
<style>
</style>
